import React, { useState, useEffect } from "react";
import "./Body.css";
import "react-multi-carousel/lib/styles.css";
import Topselling from "../Topselling/Topselling";
import ProductCollections from "../ProductCollections/ProductCollections";
import Productstorefront from "../../components/ProductStorefront/Productstorefront";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header/Header";
import AllProduct from "../../components/Products/Product";
import Flashsalescard from "../../components/Flashsalescard/Flashsalescard";
import Latestproduct from "../LatestProduct/Latestproduct";
import { useSearchParams, useLocation } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { captureButtonClick } from "../../redux/actions/buttonClick";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";

const Body = () => {
  const [searchParams] = useSearchParams();
  const { allEvents } = useSelector((state) => state.events);
  const [productdata, setProductdata] = useState(null);

  console.log(allEvents?.length);
  // const eventData = searchParams.get("isEvent");
  const location = useLocation();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  const dispatch = useDispatch();

  const handleMenuClick = () => {
    dispatch(captureButtonClick());
  };

  useEffect(() => {
    setProductdata(allEvents);
  }, [allEvents]);

  const buttonClicks = useSelector((state) => state.buttonclick);

  return (
    <div className="lndpgContainer bg-[#F0F8FF]">
      <Header />

      <div className="flex sticky top-[72px] w-full h-full z-20">
        <div
          className="bg-[#fff] p-2 w-fit cursor-pointer menu_container_sdbar"
          onClick={handleMenuClick}
        >
          {buttonClicks.buttonClicks ? (
            <IoClose size={25} />
          ) : (
            <GrMenu size={25} />
          )}
        </div>

        <div
          className={
            buttonClicks.buttonClicks
              ? "flex w-fill h-fill sticky top-0 menu_container_sdbar"
              : "hidden"
          }
        >
          <Sidebar />
        </div>
      </div>

      <div className="lndpgbody mb-5">
        <Productstorefront />
        <ProductCollections />
        {allEvents?.length > 0 && <Flashsalescard />}
        <Topselling />
        <Latestproduct group_title="Limited Stock Deals" />

        <div className="flex w-full items-center justify-center gap-5 m-y-2 mt-4">
          <div className="w-[10%] border-2 border-solid" />
          <h1 className="text-[18px] text-bold font-[800] text-[#333]">
            Items of Interest
          </h1>
          <div className="w-[10%] border-2 border-solid" />
        </div>
        <AllProduct title="" />
      </div>

      <Footer />
    </div>
  );
};

export default Body;
