// export const server = "http://localhost:8000/api/v2";
// export const server = "https://kira-store-vercel-backend.vercel.app/api/v2";
// export const server = "https://kirastores-backend.omotoyecda.org/api/v2";
export const server = "https://kirastores-backend.tripkaze.com/api/v2";

// export const server = "https://m6hsr3z5-8000.uks1.devtunnels.ms/api/v2";

export const ENDPOINT = "https://kirastore-web-socket.onrender.com/";

export const REACT_APP_ADMIN_DOMAIN = "https://stores.kirasurf.com";
// export const REACT_APP_ADMIN_DOMAIN = "http://localhost:3002";

// export const ENDPOINT = "http://localhost:4000/";
